<template>
  <v-app>
    <AppBar />
    <Drawer />
    <MainView v-if="!loading"/>
	<AddDrawer/>
    <UpdateDetailsDrawer/>
    <ViewDetailsDrawer/>
<!--    <span v-if="isLoading">MARIO</span>-->
<!--    <spinner v-if="loading || isLoading"></spinner>-->
	<alert-list />
  </v-app>
</template>
<script>
import AlertList from '@/views/Client/components/AlertList.vue';
// import Spinner from '@/views/Spinner/LoadingSpinner';
import axios from '@/plugins/axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		isLoading: false,
	},
	mutations: {
		setIsLoading (state, isLoading) {
			state.isLoading = isLoading;
		},
	},
});

axios.interceptors.request.use(config => {
	store.commit('setIsLoading', true);
	return config;
});

axios.interceptors.response.use(response => {
	store.commit('setIsLoading', false);
	return response;
}, error => {
	store.commit('setIsLoading', false);
	return Promise.reject(error);
});

export default {
	name: 'DashboardIndex',
	components: {
		AppBar: () => import('./core/AppBar.vue'),
		Drawer: () => import('./core/AppDrawer.vue'),
		MainView: () => import('./core/MainView.vue'),
		AddDrawer: () => import('./core/AddDrawer.vue'),
		ViewDetailsDrawer: () => import('./core/ViewDetailsDrawer.vue'),
		UpdateDetailsDrawer: () => import('./core/UpdateDetailsDrawer.vue'),
		AlertList,
		// Spinner,
	},
	computed: {
		isLoading () {
			return store.state.isLoading;
		},
	},
	async mounted () {
		await this.loginWithToken();
	},
	data () {
		return {
			prevRoute: null,
			loading: true,
		};
	},
	beforeRouteUpdate (to, from, next) {
		this.loading = true;
		next();
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.prevRoute = from;
		});
	},
	watch: {
		$route () {
			this.loading = false;
		},
	},
	methods: {
		async loginWithToken () {
			if (localStorage.sessionToken) {
				if (this.prevRoute !== null) {
					if (this.prevRoute.path === '/auth/login') {
						this.loading = false;
						return;
					}
				}
				await this.$store.dispatch('authentication/doLoginWithToken').then(async () => {
					this.loading = false;
					await this.setupwebsocket();
				})
					.catch(() => {
						this.$router.replace(this.$route.query.redirect || '/auth/login');
						this.$store.dispatch('alerts/clear');
					});
			}
			return null;
		},
		async setupwebsocket () {
			switch (this.$store.getters['authentication/getRole']) {
			case 'SYSTEM_ADMIN':
			case 'ORGANIZATION_ADMIN':
			case 'HOSPITAL_ADMIN':
				break;
			default:
				await this.$store.dispatch('setupWebSockets');
				break;
			}
		},
	},
};
</script>
